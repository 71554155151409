<template>
  <div>
    <b-form @reset="onFormReset" @submit.prevent="onFormSubmit">
      <b-form-select :options="messageOptions" v-model="selectedMessage">
        <template v-slot:first>
          <option :value="null" disabled>-- Please select a message --</option>
        </template>
      </b-form-select>
      <hr />
      <b-form-group
        id="fieldset-title"
        description="This is the subject line for the email"
        label="Email Subject"
        label-for="input-title"
      >
        <b-form-input id="input-title" v-model="messageTitle" placeholder="Message Subject" required></b-form-input>
      </b-form-group>
      
      <b-form-group
        id="fieldset-content"
        description="This is the body of the email"
        label="Email Content"
        label-for="input-content"
      >
        <b-form-textarea id="input-content" v-model="messageContent" placeholder="Message Content" :rows="15"></b-form-textarea>
      </b-form-group>
      <b-row class="mt-2">
        <b-col cols="!2" lg="6"><b-btn type="reset" variant="danger">Reset</b-btn></b-col>
        <b-col cols="!2" lg="6" class="text-right"><b-btn type="submit" variant="success">Save Post</b-btn></b-col>
      </b-row>
    </b-form>
    <!-- hr />
    <b-row class="mt-2">
      <b-col cols="!2" lg="4">
        <b-form @submit="sendTest">
          <b-form-group
            id="fieldset-test"
            description="Use this option to send a test email."
            label="Test Message"
            label-for="input-test"
          >
            <b-input-group prepend="Recipient" class="mt-3">
              <b-form-input id="input-test" type="email" v-model="testRecipient" placeholder="Message Recipient" required></b-form-input>
              <b-button variant="success" type="submit">Send a Test</b-button>
            </b-input-group>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const defaultContnet = {
  'member-event-registration': {
    title: 'Event Registration Confirmation',
    content: `Thank you for registering for {{eventTitle}}. We look forward to seeing you there!\n\nSincerely,\nThe 'N' Zone Team`
  }
}
export default {
  name: 'CustomMessages',
  data () {
    return {
      contentId: null,
      selectedMessage: null,
      messageTitle: null,
      messageContent: null,
      testRecipient: null
    }
  },
  computed: {
    ...mapGetters ([
      'content'
    ]),
    messageOptions () {
      return [
        { value: 'member-welcome', text: 'Member Welcome' },
        { value: 'member-event-registration', text: 'Event Registration Confirmation (generic)' }
      ]
    }
  },
  watch: {
    selectedMessage () {
      this.setMessage()
    }
  },
  mounted () {
    if (this.messageOptions.length === 1) {
      this.selectedMessage = this.messageOptions[0].value
    }
    this.testRecipient = this.currentUser.email
    this.setMessage()
  },
  methods: {
    ...mapActions([
      'getContent',
      'postContent',
      'putContent'
    ]),
    onFormReset () {

    },
    contentCheck () {},
    async onFormSubmit () {
      try {
        this.contentCheck()
        if (!this.contentId) {
          let content = {
            author:   this.currentUser['@id'],
            type:     'email_message',
            slug:     this.selectedMessage,
            summary:  '',
            title:    this.messageTitle,
            content:  this.messageContent
          }
          if (this.currentBusiness) {
            content.business = this.currentBusiness['@id']
          }
          await this.postContent(content)
          await this.setMessage()
        } else {
          let content = {
            id:       this.contentId,
            title:    this.messageTitle,
            content:  this.messageContent
          }
          await this.putContent(content)
        }
        this.showSuccess()
      } catch (e) {}
    },
    async setMessage () {
      this.messageTitle = null
      this.messageContent = null
      if (this.currentBusiness && this.selectedMessage) {
        if (defaultContnet[this.selectedMessage]) {
          this.messageTitle = defaultContnet[this.selectedMessage].title
          this.messageContent = defaultContnet[this.selectedMessage].content
        }
        await this.getContent({
          business: this.currentBusiness.id,
          slug: this.selectedMessage
        })
        if (this.content.length) {
          this.contentId = this.content[0].id
          this.messageTitle = this.content[0].title
          this.messageContent = this.content[0].content
        }
      }
      if (this.selectedMessage && !(this.currentBusiness || this.content.length)) {
        await this.getContent({
          business: null,
          slug: this.selectedMessage
        })
        if (this.content.length) {
          this.contentId = this.currentBusiness ? null : this.content[0].id
          this.messageTitle = this.content[0].title
          this.messageContent = this.content[0].content
        }
      }
    },
    sendTest () {
      console.info('send a test to ' + this.testRecipient)
      // @TODO
    }
  }
}
</script>
